// Returned value called Lightness contrast (Lc)
// LC values can vary from 0 to 106 for dark text on a light background, and 0 to -108 for light text on a dark background
/* Recommended font settings for the following Lc levels:
|  Lc Level  | Font Size (px)        | Font Weight    |
|------------|-----------------------|----------------|
| Lc 90      | ≥ 18                  | 300            |
|            | ≥ 14                  | 400 (normal)   |
|            | ≥ 12 (non-body text)  | 400            |
|            | ≥ 24 (thin fonts)     | 200            |
|            | > 36 (very large text)| bold           |
|------------|-----------------------|----------------|
| Lc 75      | ≥ 24                  | 300            |
|            | ≥ 18                  | 400            |
|            | ≥ 16                  | 500            |
|            | ≥ 14                  | 700            |
|            | ≥ 15 (non-body text)  | 400            |
|------------|-----------------------|----------------|
| Lc 60      | ≥ 48                  | 200            |
|            | ≥ 36                  | 300            |
|            | ≥ 24                  | 400 (normal)   |
|            | ≥ 21                  | 500            |
|            | ≥ 18                  | 600            |
|            | ≥ 16                  | 700            |
|------------|-----------------------|----------------|
| Lc 45      | ≥ 36                  | 400 (normal)   |
|            | ≥ 24                  | bold           |
|------------|-----------------------|----------------|
| Lc 30      | ≥ 5.5px solid element | N/A            |
|------------|-----------------------|----------------|
| Lc 15      | ≥ 5px solid element   | N/A            |
https://git.apcacontrast.com/documentation/APCA_in_a_Nutshell.html
*/

import { type RGB, hexToRGB } from "./hexToRGB";

const linearize = (val: number): number => (val / 255.0) ** 2.4;

const clampLuminance = (luminance: number): number => {
  const blkThrs = 0.022;
  const blkClmp = 1.414;

  if (luminance > blkThrs) {
    return luminance;
  }

  return Math.abs(blkThrs - luminance) ** blkClmp + luminance;
};

const getLuminance = ({ red, green, blue }: RGB): number => {
  const y =
    0.2126729 * linearize(red) +
    0.7151522 * linearize(green) +
    0.072175 * linearize(blue);
  return clampLuminance(y);
};

const getContrast = (background: RGB, foreground: RGB): number => {
  const deltaYmin = 0.0005;
  const scale = 1.14;

  const backgroundLuminance = getLuminance(background);
  const foregroundLuminance = getLuminance(foreground);

  if (Math.abs(backgroundLuminance - foregroundLuminance) < deltaYmin) {
    return 0.0;
  }

  if (backgroundLuminance > foregroundLuminance) {
    return (backgroundLuminance ** 0.56 - foregroundLuminance ** 0.57) * scale;
  }

  if (backgroundLuminance < foregroundLuminance) {
    return (backgroundLuminance ** 0.65 - foregroundLuminance ** 0.62) * scale;
  }

  return 0.0;
};

const scaleContrast = (contrast: number): number => {
  const loClip = 0.001;
  const loConThresh = 0.035991;
  const loConFactor = 27.7847239587675;
  const loConOffset = 0.027;

  const absContrast = Math.abs(contrast);

  if (absContrast < loClip) {
    return 0.0;
  }

  if (absContrast <= loConThresh) {
    return contrast - contrast * loConFactor * loConOffset;
  }

  if (contrast > loConThresh) {
    return contrast - loConOffset;
  }

  if (contrast < -loConThresh) {
    return contrast + loConOffset;
  }

  return 0.0;
};

export const getAPCAContrast = (
  backgroundHex: string,
  foregroundHex: string,
): number => {
  const bgRgb = hexToRGB(backgroundHex);
  const fgRgb = hexToRGB(foregroundHex);
  const contrast = getContrast(bgRgb, fgRgb);
  const scaledContrast = scaleContrast(contrast);
  return scaledContrast * 100;
};
