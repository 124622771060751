import { styled } from "@zenchef/styled-system/jsx";
import {
  aromaBadge,
  AromaBadgeVariantProps,
} from "@zenchef/styled-system/recipes";
import { PropsWithChildren } from "react";

interface BadgeProps extends AromaBadgeVariantProps, PropsWithChildren {}

const AromaBadge = styled("span", aromaBadge);

export type { BadgeProps };
export default AromaBadge;
